import React from "react";
import SignIn from "../components/Signin";

const SigninPage = () => {
  return (
    <>
      <SignIn />
    </>
  );
};

export default SigninPage;
